define('data',[
    'utils',
    'ui',
    'mxlist-result'
],
function (UTILS, UI, MXLIST_RESULT) {
    "use strict";

    var __, exports;
    __ = {};
    exports = {
        __: __
    };

    __.errorMessages = {
        'tlsaerror': 'DNS lookup error for TLSA records',
        'mxerror': 'Lookup error for MX records',
        'v6error': 'DNS lookup error for AAAA records',
        'v4error': 'DNS lookup error for A records'
    };
    __.element;
    __.domainName;
    __.csrfToken;
    __.nrOfMx;
    __.nrOfMxResults = 0;
    __.nrOfSuccessfulMxResults = 0;
    __.mxListResult;
    __.cacheDelta = new Date(1000 * 60 * 5);

    exports.start = function(opts) {
        __.element = opts.element;
        __.domainName = opts.domainName;
        __.csrfToken = opts.csrfToken;
        jQuery.ajax('/get_mx', {
            method: 'POST',
            data: {
                domain: __.domainName,
                revalidate: !!window.daneRevalidate ? 1 : 0,
                csrfmiddlewaretoken: __.csrfToken
            }
        }).done(function(data, statusCode) {
            var cacheInfo;
            /*
             * Store MxListResult instance.
             */
            __.mxListResult = MXLIST_RESULT.createMxListResult(data);
            /*
             * If the result has been cached, display the revalidate element.
             */
            if(!!__.mxListResult.cached) {
                __.manageRevalidateButton(__.mxListResult.cached);
            }
            /*
             * If domain is DNSSEC insecure, stop here and show errors.
             */
            if(!!__.mxListResult.insecure) {
                var hasTimeoutOrServfail = false; 
                if(__.mxListResult.domainErrors && __.mxListResult.domainErrors.length > 0) {
                    __.mxListResult.domainErrors.forEach(function(error) {
                        if(error.msg && (error.msg === 'SERVFAIL' || error.msg === 'timeout')) {
                            hasTimeoutOrServfail = true;
                        }
                    });
                }
                if(!hasTimeoutOrServfail) {
                    UI.showDomainError('DNSSEC: Insecure Domain.');
                }
                UI.setDomainStatus('dnssec', 'danger');
                UI.setDomainStatus('tlsa', 'default');
                UI.setDomainStatus('dane', 'default');
                __.sendLogRequest();
            }
            else {
                /*
                 * No hosts to check => Send log request to backend.
                 */
                if(__.mxListResult.checkHosts.length === 0) {
                    __.sendLogRequest();
                }
                /*
                 * Display all elements that depend on Mx records.
                 */
                if(__.mxListResult.hosts.length > 0) {
                    var onHasMxRecordsEl = document.querySelectorAll('.on-has-mx-records');
                    if(onHasMxRecordsEl !== null) {
                        var l = onHasMxRecordsEl.length;
                        while(l--) {
                            UTILS.removeClass(onHasMxRecordsEl[l], 'hidden');
                        }
                    }
                }
                /*
                 * Add result element and check global stati for DNSSEC and TLSA.
                 */
                UI.addResultElement(opts.element, data.mx_list, data.domain, data.mx_list2);
                __.checkDnssec(__.mxListResult.hosts);
                __.checkTlsa(__.mxListResult.hosts);
                __.checkMxlistErrors(__.mxListResult.hosts);
                /*
                 * Get the results for all relevant MX hosts.
                 */
                __.mxListResult.checkHosts.forEach(function(mx) {
                    __.getMxResults(mx);
                });
                /*
                 * Set default SMTP state for for all ignored hosts.
                 */
                __.mxListResult.ignoreHosts.forEach(function(mx) {
                    UI.setMxStatus(mx.host, 'dane', 'default');
                });
            }
            /*
             * domain errors
             */
            __.checkDomainErrors(__.mxListResult.domainErrors);
        });
    };

    __.checkDomainErrors = function(domainErrors) {
        if(!!domainErrors && domainErrors.length > 0) {
            domainErrors.forEach(function(item) {
                var type = __.errorMessages[item.type] || item.type; 
                UI.showDomainError(type + ': ' + item.msg, 'warning');
            });
        }
    }

    __.checkMxlistErrors = function(hosts) {
        hosts.forEach(function(item) {
            if(!!(item.errors && item.errors.length > 0)) {
                item.errors.forEach(function(error) {
                    var type = __.errorMessages[error.type] || error.type;
                    UI.showMxError({
                        host: item.host,
                        msg: type + ': ' + error.msg
                    }, 'warning');
                });
            }

        });
    };

    __.manageRevalidateButton = function(cacheTime) {
        var timeDifference;
        var timeLeft;
        var timeEl;
        var now;
        var interval;
        var reval = document.querySelector('.revalidate');
        cacheTime = new Date(cacheTime);
        now = new Date();
        timeDifference = new Date(now - cacheTime);
        jQuery('#note-cached-result').removeClass('hidden');
        if(timeDifference < __.cacheDelta === true) {
            timeLeft = new Date(__.cacheDelta - timeDifference);
            timeEl = document.getElementById('cached-time-left');
            if(!!timeEl) {
                UTILS.removeClass(reval, 'hidden');
                __.updateTimeEl(timeEl, timeLeft);
                interval = setInterval(function() {
                    timeLeft = new Date(__.cacheDelta - (new Date() - cacheTime));
                    if(timeLeft <= 1000) {
                        timeEl.innerHTML = '';
                        UTILS.addClass(reval, 'hidden');
                        jQuery('#revalidate-button').removeClass('hidden').on('click', function() {
                            jQuery('#revalidate-form').submit();
                        });
                        clearInterval(interval);
                    }
                    else {
                        __.updateTimeEl(timeEl, timeLeft);
                    }
                }, 1000);
            }
        }
        else {
            jQuery('#revalidate-button').removeClass('hidden').on('click', function() {
                jQuery('#revalidate-form').submit();
            });
        }
    };
    __.updateTimeEl = function(el, timeLeft) {
        var min = timeLeft.getMinutes();
        var sec = timeLeft.getSeconds();
        if(sec < 10) {
            sec = '0' + sec;
        }

        el.innerHTML = ' in ' + min + ':' + sec;
    };

    __.checkDnssec = function(hosts) {
        var passed = 0;
        hosts.forEach(function(item) {
            if(item.insecure === false) {
                UI.setMxStatus(item.host, 'dnssec', 'success');
                passed += 1;
            }
            else {
                UI.setMxStatus(item.host, 'dnssec', 'danger');
                UI.showMxError({
                    host: item.host,
                    msg: 'Host is not in a DNSSEC protected zone.'
                }, 'danger');
            }
        });
        if(passed === hosts.length) {
            UI.setDomainStatus('dnssec', 'success');
        }
        else {
            UI.setDomainStatus('dnssec', 'warning');
        }
    };

    __.checkTlsa = function(hosts) {
        var passed = 0;
        var usable = 0;
        var unusable = 0;
        /*
         * Loop over hosts to set flags.
         */
        hosts.forEach(function(item) {
            var hasServfail = false; 
            if(item.errors && item.errors.length > 0) {
                item.errors.forEach(function(error) {
                    if(error.msg && error.msg === "SERVFAIL") {
                        hasServfail = true;
                    }
                });
            }
            // insecure
            if (!!item.insecure) {
                UI.setMxStatus(item.host, 'tlsa', 'default');
            }
            // no tlsa rrs at all
            else if(item.usableTlsa === false && item.unusableTlsa === false) {
                UI.setMxStatus(item.host, 'tlsa', 'warning');
                if(!hasServfail) {
                    UI.showMxError({
                        host: item.host,
                        msg: 'No TLSA records.'
                    }, 'warning');
                }
            }
            else {
                // usable tlsas
                if(item.unusableTlsa === true) {
                    unusable += 1;
                    if(item.usableTlsa === true) {
                        UI.showMxError({
                            host: item.host,
                            msg: 'There are some unusable TLSA RRs present'
                        }, 'warning');
                    }
                }
                if(item.usableTlsa === true) {
                    var status = item.unusableTlsa === true ? 'warning' : 'success';
                    UI.setMxStatus(item.host, 'tlsa', status);
                    passed += 1;
                    usable += 1;
                }
                else {
                    UI.setMxStatus(item.host, 'tlsa', 'danger');
                    UI.showMxError({
                        host: item.host,
                        msg: 'No usable TLSA RRs found.'
                    }, 'warning');
                }
            }
        });
        if(hosts.length === 0) {
            UI.setDomainStatus('tlsa', 'warning');
            UI.showDomainError('No MX hosts.', 'warning');
        }
        else if(passed === hosts.length && hosts.length > 0) {
            UI.setDomainStatus('tlsa', 'success');
        }
        else if(usable === 0 && unusable > 0) {
            UI.setDomainStatus('tlsa', 'warning');
            UI.setDomainStatus('dane', 'default');
        }
        else if(passed === 0) {
            UI.setDomainStatus('tlsa', 'warning');
            UI.setDomainStatus('dane', 'default');
        }
        else {
            UI.setDomainStatus('tlsa', 'warning');
            UI.setDomainStatus('dane', 'warning');
        }
    };

    __.getMxResults = function(mx) {
        jQuery.ajax('/check_mx', {
            method: 'POST',
            data: {
                csrfmiddlewaretoken: __.csrfToken,
                domain: mx.domain,
                revalidate: !!window.daneRevalidate ? 1 : 0,
                mx: mx.host
            }
        }).done(function(data) {
            if(!!data) {
                if(typeof(data) === 'string') {
                    data = JSON.parse(data);
                }
                __.processMxResult(mx, data);
            }
        });
    };

    __.processMxResult = function(mx, result) {
        __.nrOfMxResults += 1;
        // everything OK
        if(result.hardfail === 0 && result.softfail === 0 && result.verified > 0) {
            UI.setMxStatus(mx.host, 'dane', 'success');
            __.nrOfSuccessfulMxResults += 1;
        }
        // something went wrong
        else {
            var errorLevel = 'warning';
            if(result.hardfail > 0) {
                msg = (!!result.all_tlsas_failed)
                    ? 'All TLSA RRs failed. (See details.)'
                    : 'Some TLSA RRs failed. (See details.)'
                errorLevel = 'danger';
                UI.showMxError({
                    host: mx.host,
                    msg: msg
                }, 'danger');
            }
            __.getErrorMessagesFromMxResult(result).forEach(function(item) {
                item['host'] = mx.host;
                UI.showMxError(item, errorLevel);
            });
            UI.setMxStatus(mx.host, 'dane', errorLevel);
        }
        __.markTlsaItems(result, mx);
        if(__.nrOfMxResults === __.mxListResult.checkHosts.length) {
            __.sendLogRequest();
            var daneStatus = (__.nrOfSuccessfulMxResults === __.mxListResult.hosts.length)
                ? 'success' : 'warning';
            UI.setDomainStatus('dane', daneStatus);
        }
    };
    __.markTlsaItems = function(result, mx) {
        ['v4', 'v6'].forEach(function(item) {
            if(!!result.results && !!result.results[item]) {
                var vx = result.results[item];
                for(var ip in vx) {
                    if(!!vx[ip].failed) {
                        vx[ip].failed.forEach(function(failed) {
                            var hash = failed[0][3];
                            var reason = failed[1];
                            var sel = 'div[data-mx-host="'
                                + mx.host + '"] li[data-tlsa-str="' + hash + '"]';
                            var el = document.querySelector(sel);
                            UTILS.addClass(el, 'list-group-item-danger');
                            var msg = document.createElement('strong');
                            msg.innerHTML = ' - ' + reason;
                            el.appendChild(msg);
                        });
                    }
                    if(!!vx[ip].passed) {
                        vx[ip].passed.forEach(function(passed) {
                            var hash = passed[0][3];
                            var sel = 'div[data-mx-host="'
                                + mx.host + '"] li[data-tlsa-str="' + hash + '"]';
                            var el = document.querySelector(sel);
                            UTILS.addClass(el, 'list-group-item-success');
                        });
                    }
                }
            }
        });
    }

    __.getErrorMessagesFromMxResult = function(result) {
        var msgs = [];
        if(!!result.errors) {
            for(var ip in result.errors) {
                var obj = result.errors[ip];
                obj.ip = ip;
                msgs.push(obj);
            }
        }
        return msgs;
    };

    __.sendLogRequest = function() {
        jQuery.ajax('/log', {
            method: 'POST',
            data: {
                domain: __.domainName,
                csrfmiddlewaretoken: __.csrfToken
            }
        }).done(function(data, statusCode) {
        });
    };

    return exports;
});

