define('mx',[
],
function() {
    "use strict";

    var __, exports, Mx;

    __ = {};
    exports = {
        __: __
    };

    Mx = function(data, domain) {
        this.data = data;
        this.domain = domain;
        this.host = data.domain;
        this.check = !!data.check;
        this.insecure = !!data.insecure;
        this.usableTlsa = !!data.usable_tlsa;
        this.unusableTlsa = !!data.unusable_tlsa;
        this.errors = data.errors;
    };

    Mx.prototype.addCheckResult = function(data) {
    };

    exports.createMx = function(data, domain) {
        return new Mx(data, domain);
    };

    return exports;
});

