define('events',[],
function() {
    "use strict";

    var exports = {};

    exports.on = function(el, eventName, handler) {
        if(el.addEventListener) {
            el.addEventListener(eventName, handler);
        }
        else {
            el.attachEvent('on' + eventName, function() {
                handler.call(el);
            });
        }
    };

    exports.off = function(el, eventName, handler) {
        if(el.removeEventListener) {
            el.removeEventListener(eventName, handler);
        }
        else {
            el.detachEvent('on' + eventName, function() {
                handler.call(el);
            });
        }
    }

    return exports;
});

