define('utils',[
],
function() {
    "use strict";

    var exports = {};

    exports.addClass = function(el, className) {
        if(el === null) {
            return false;
        }
        if(el.classList) {
            el.classList.add(className);
        }
        else {
            el.className +=  className;
        }
    };

    exports.removeClass = function(el, className) {
        if(el === null) {
            return false;
        }
        if(el.classList) {
            el.classList.remove(className);
        }
        else {
            el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    return exports;
});

