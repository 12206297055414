define('init',[
    'data',
    'livesearch'
],
function(DATA, LIVESEARCH) {
    "use strict";

    var opts, element, csrfInput, listEl;
    
    element = document.getElementById('mx-list');

    if(!!element) {
        csrfInput = document.querySelector('input[name="csrfmiddlewaretoken"]');
        opts = {};
        opts.element = element;
        opts.domainName = jQuery('#validation-result-container')
            .attr('data-domain-name');
        opts.csrfToken = csrfInput.value;
        DATA.start(opts);
    }

    jQuery(document).ready(function() {
        jQuery('.validate-focus').on('click', function() {
            jQuery('#id_domain').focus();
        });

        LIVESEARCH.init(document.querySelector('input[name="domain"]'));
    });
});

