define('livesearch',[
    'events',
    'text!templates/livesearch.html'
],
function(EVENTS, TPL) {
    "use strict";

    var exports = {};
    var AJAX_PATH = '/find_domain';
    var _tplFn = doT.template(TPL);
    var _container;
    var _focusIndex = null;

    var isArrowUp = function(e) {
        if(!!e.key && e.key === 'ArrowUp') {
            return true;
        }
        else if(!!e.keyIdentifier && e.keyIdentifier === "Up") {
            return true;
        }
        else if(!!e.which && e.which === 38) {
            return true;
        }
        return false;
    };

    var isArrowDown = function(e) {
        if(!!e.key && e.key === 'ArrowDown') {
            return true;
        }
        else if(!!e.keyIdentifier && e.keyIdentifier === "Down") {
            return true;
        }
        else if(!!e.which && e.which === 40) {
            return true;
        }
        return false;
    };

    var isEscape = function(e) {
        if(!!(e.keyCode  && e.keyCode == 27)) {
            return true;
        }
        if(!!(e.which  && e.which == 27)) {
            return true;
        }
        return false;
    };

    var setFocus = function(index, items) {
        items = items || [];
        if(index === false) {
            _focusIndex = null;
        }
        for(var i=0, l=items.length; i<l; i++) {
            if(index === false) {
                jQuery(items[i]).removeClass('focus');
            }
            else {
                if(index === i) {
                    jQuery(items[i]).addClass('focus');
                }
                else {
                    jQuery(items[i]).removeClass('focus');
                }
            }
        }
    };

    var shiftFocus = function(el, direction) {
        if(!el) {
            return;
        }
        var items = el.querySelectorAll('.livesearch-list-item');
        if(items.length === 0) {
            return;
        }
        if(direction === 'down') {
            if(_focusIndex === null) {
                _focusIndex = 0;
            }
            else if(_focusIndex >= 0 && items.length - (_focusIndex + 1) > 0) {
                _focusIndex += 1;
            }
            else if(_focusIndex >= 0 && items.length - _focusIndex === 1) {
                _focusIndex = 0;
            }
        }
        else if(direction === 'up') {
            if(!_focusIndex) {
                _focusIndex = items.length - 1;
            }
            else if(_focusIndex > 0 && items.length - (_focusIndex + 1) >= 0) {
                _focusIndex -= 1;
            }
        }
        setFocus(_focusIndex, items);
    };

    exports.update = function(el) {
        var el = jQuery(el);
        var val = el.val();
        if(!_container) {
            _container = document.querySelector('#livesearch');
        }
        if(val.length >= 2) {
            jQuery.ajax(AJAX_PATH, {
                method: 'GET',
                data: {
                    query: val
                }
            }).done(function(data) {
                setFocus(false);
                if(!!data.domains && data.domains.length > 0) {
                    _container.innerHTML = _tplFn(data);
                }
                else {
                    _container.innerHTML = '';
                }
            }).fail(function() {
            });
        }
        else {
            _container.innerHTML = '';
        }
    };

    exports.init = function(element) {
        EVENTS.on(element, 'keyup', function(e) {
            var container;
            if(isEscape(e)) {
                console.log('ESCAPE');
                container = document.querySelector('#livesearch');
                if(!!container) {
                    console.log('emptying');
                    container.innerHTML = '';
                }

            }
            else if(isArrowUp(e) || isArrowDown(e)) {
                shiftFocus(_container, isArrowUp(e) ? 'up' : 'down');
            }
            else {
                exports.update(e.target);
            }
        });
        EVENTS.on(element, 'click', function(e) {
            console.log('clicked the input');
            setFocus(false);
        });
        EVENTS.on(document.querySelector('#validation-form'), 'submit', function(e) {
            if(_focusIndex !== null && e.preventDefault) {
                e.preventDefault();
                var item = document.querySelectorAll('li.livesearch-list-item')[_focusIndex];
                var domain = jQuery(item).find('a').attr('data-domain');
                document.getElementById('id_domain').value = domain;
                e.target.submit();
            }
        });
    };

    return exports;
});

