define('mxlist-result',[
    'mx'
],
function(MX) {
    "use strict";
    
    var __, exports, MxListResult, Mx;

    __ = {};
    exports = {
        __: __
    };

    __.getHosts = function(mxList, domain) {
        var hosts = [];
        mxList.forEach(function(item) {
            hosts.push(MX.createMx(item, domain));
        });
        return hosts;
    };

    MxListResult = function(data) {
        this.data = data;
        this.domain = data.domain;
        this.insecure = data.ad == 0 ? true : false;
        this.hosts = __.getHosts(data.mx_list, this.domain);
        this.mxList2 = (!!data.mx_list2) ? __.getHosts(data.mx_list2, this.domain) : null;
        this.checkHosts = this.getCheckHosts();
        this.ignoreHosts = this.getIgnoreHosts();
        this.cached = data.cached;
        this.domainErrors = data.errors || [];
    };

    MxListResult.prototype.getMxByHost = function(host) {
        this.hosts.forEach(function(item) {
            if(item.host === host) {
                return item;
            }
        });
        return null;
    };

    MxListResult.prototype.getCheckHosts = function() {
        var checkHosts = [];
        this.hosts.forEach(function(item) {
            if(!!item.check) {
                checkHosts.push(item);
            }
        });
        return checkHosts;
    };

    MxListResult.prototype.getIgnoreHosts = function() {
        var ignoreHosts = [];
        this.hosts.forEach(function(item) {
            if(!item.check) {
                ignoreHosts.push(item);
            }
        });
        return ignoreHosts;
    };
    
    exports.createMxListResult = function(data) {
        return new MxListResult(data);
    };

    return exports;
    
});

