define('ui',[
    'text!templates/result-container.html',
    'text!templates/mx-result-item.html',
    'text!templates/mx-error-msg.html'
],
function(RESULT_CONTAINER_TPL, MX_NAV_ITEM_TPL, MX_ERROR_MSG_TPL) {
    "use strict";

    var __, exports;
    __ = {};
    exports = {
        __: __
    };
    __.resultContainer = false;
    __.domainInfoEl = document.querySelector('.domain-info');

    __.resultContainerTplFn = doT.template(RESULT_CONTAINER_TPL);
    __.errorTplFn = doT.template(MX_ERROR_MSG_TPL);
    
    exports.showDomainError = function(msg, level) {
        level = level || 'danger'
        jQuery(__.domainInfoEl).append('<div class="alert alert-' + level + '">' + msg + '</div>');
    };

    exports.showMxError = function(error, level) {
        jQuery('.mx-result-errors[data-mx-host="' + error.host + '"]')
            .append(__.errorTplFn({
                ip: error.ip,
                host: error.host,
                msg: error.msg,
                level: level
            }));
    };

    /*
     * Create result template.
     */
    exports.addResultElement = function(el, mxList, domain, mxList2) {
        tplFn = doT.template(MX_NAV_ITEM_TPL);
        jQuery(el).append(tplFn({
            hosts: mxList,
            domain: domain,
            mxList2: mxList2
        }));
    };

    /*
     * Set label stati.
     */
    __.setLabelStatus = function(el, status) {
        var iconClass = status === 'success'
            ? 'fa fa-check-circle'
            : 'fa fa-exclamation-circle';
        el.className = 'label label-' + status;
        el.querySelector('i').className = iconClass;
    };
    exports.setDomainStatus = function(category, status) {
        var el = document.querySelector('#domain-' + category + '-indicator');
        if(el !== null) {
            __.setLabelStatus(el, status);
        }
    };
    exports.setMxStatus = function(mxHost, category, status) {
        var el = document.querySelector('.mx-labels[data-mx-host="'
            + mxHost + '"]'
            + ' span[data-indicator-type="' + category + '"]');
        if(el !== null) {
            __.setLabelStatus(el, status);
        }
    };

    /*
     * Return API.
     */
    return exports;
});

